<template>
  <div class="_wrap">

    <div class="_swiper">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in $store.state.homeData.banner" :key="index">
          <img :src="$IMG_URL + item.image" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="_baseInfo" v-if="seen">
      <div class="_auto">
        <div class="_left">

          <div class="_start">
            <p class="_h4">场馆开放时间</p>
            <p class="_p _fontNum">{{ $store.state.homeData.web_data.open_time }}</p>

          </div>

          <div class="_stop">
            <p> <span class="_fontNum">{{ $store.state.homeData.web_data.stop_time }}</span> 后停止入馆</p>
            <p class="_h4">周一闭馆,法定节假日除外</p>
          </div>

          <div class="_tel">
            <p>官方联系电话</p>
            <p class="_p _fontNum">{{ $store.state.homeData.web_data.tel }}</p>
          </div>



        </div>

        <div class="_right">
          <div class="_qrcode">
            <div class="_code_img">
              <img :src="$IMG_URL + $store.state.homeData.web_data.appoint_qrcode" alt="">

            </div>
            <div class="_tip">
              <p>关注公众号</p>
              <p>即可参观预约</p>
            </div>
          </div>

          <div class="_r_btn">
            <p>
              <span @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '游览须知'
          }
        })">游览须知</span>
              <span @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '交通路线'
          }
        })">交通路线</span>

            </p>
            <p>
              <span @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '讲解服务'
          }
        })">讲解服务</span>
              <span @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '参观服务'
          }
        })">参观服务</span>

            </p>

          </div>

        </div>
      </div>
    </div>


    <div class="_baseInfo1" v-if="!seen">

      <div class="_auto">
        <div class="_tt1">
          <div class="_text1">
            <p>场馆开放时间</p>
            <p class="_tm">{{ $store.state.homeData.web_data.open_time }}</p>
          </div>

          <div class="_text2">
            <p><span class="_sp1">{{ $store.state.homeData.web_data.stop_time }}</span><span>后停止入馆</span></p>
            <p>周一闭馆,法定节假日除外</p>
          </div>
        </div>

        <div class="_tt2">
          <span>官方联系电话</span>
          <span class="_sp1">

            <a :href="'tel:' + $store.state.homeData.web_data.tel">{{ $store.state.homeData.web_data.tel }}</a>
          </span>
        </div>

        <div class="_tt3">

          <div class="_lis">
            <div class="_li" @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '游览须知'
          }
        })">
              <img src="@/assets/images/xz.png" alt="">
              <p>游览须知</p>
            </div>
            <div class="_li" @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '讲解服务'
          }
        })">
              <img src="@/assets/images/jj.png" alt="">
              <p>讲解服务</p>
            </div>
            <div class="_li" @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '交通路线'
          }
        })">
              <img src="@/assets/images/lx.png" alt="">
              <p>交通路线</p>
            </div>
            <div class="_li" @click="$router.push({
          path: '/exhibit',
          query: {
            tit: '参观服务'
          }
        })">
              <img src="@/assets/images/gdfw.png" alt="">
              <p>参观服务</p>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="_content">

      <div class="_fist">

        <div class="_f_l">
          <div class="_h4">
            <span class="_bgc"></span>

            <div class="_more" v-if="seen" @click="$router.push({
          path: '/information',
          query: {
            tit: '动态'
          }
        })">
              <span class="_x">★</span>
              <span class="_d">★</span>
              <span class="_gd">查看更多</span>
              <span class="_d">★</span>
              <span class="_x">★</span>
            </div>

          </div>

          <div class="_cont">
            <div class="_li" v-for="(item, index) in $store.state.homeData.dynamic" :key="index" @click="goOther(item)">
              <div class="_c_l">
                <span class="_d">{{ item.release_date.substring(5, 7) }}</span>
                <span>/{{ item.release_date.substring(8, 10) }}</span>
              </div>
              <div class="_c_r">
                <div class="_c_h4">{{ item.title }}</div>
                <p>{{ item.description }}</p>
                <p>热度：{{ item.hits }}</p>
              </div>
            </div>
          </div>


          <div class="_more" v-if="!seen" @click="$router.push({
          path: '/information',
          query: {
            tit: '动态'
          }
        })">
            <span class="_x">★</span>
            <span class="_d">★</span>
            <span class="_gd">查看更多</span>
            <span class="_d">★</span>
            <span class="_x">★</span>
          </div>
        </div>

        <div class="_f_l _f_r">
          <div class="_h4">
            <span class="_bgc"></span>

            <div class="_more" v-if="seen" @click="$router.push({
          path: '/takeCare',
          query: {
            tit: '社教活动'
          }
        })">
              <span class="_x">★</span>
              <span class="_d">★</span>
              <span class="_gd">查看更多</span>
              <span class="_d">★</span>
              <span class="_x">★</span>
            </div>

          </div>

          <div class="_f_cont" @click="goDetail">
            <div class="_img">
              <img :src="$IMG_URL + $store.state.homeData.SEA[0].cover" alt="">
            </div>
            <div class="_h5">
              {{ $store.state.homeData.SEA[0].title }}
            </div>

          </div>


          <div class="_more" v-if="!seen" @click="$router.push({
          path: '/takeCare',
          query: {
            tit: '社教活动'
          }
        })">
            <span class="_x">★</span>
            <span class="_d">★</span>
            <span class="_gd">查看更多</span>
            <span class="_d">★</span>
            <span class="_x">★</span>
          </div>
        </div>
      </div>

    </div>



    <!-- 展览 -->
    <div class="_clzl">
      <div class="_au">
        <div class="_h4"></div>
        <div class="_list" v-if="seen">
          <div class="_li" @click="$router.push({ path: 'visit' })">
            <div class="_bgImg">
              <img src="@/assets/images/Group 161.png" alt="">
            </div>

            <div class="_ab">
              <img class="_name" src="@/assets/images/Group 146.png" alt="">
              <span></span>
              <img class="_xq" src="@/assets/images/Group 227.png" alt="">
            </div>
          </div>
          <div class="_li" @click="$router.push({ path: 'visit', query: { tit: '专题展览' } })">
            <div class="_bgImg">
              <img src="@/assets/images/Rectangle 72.png" alt="">
            </div>

            <div class="_ab">
              <img class="_name" src="@/assets/images/Group 141.png" alt="">
              <span></span>
              <img class="_xq" src="@/assets/images/Group 227.png" alt="">
            </div>
          </div>

          <div class="_li" @click="$router.push({ path: 'visit', query: { tit: '临时展览' } })">
            <div class="_bgImg">
              <img src="@/assets/images/Rectangle 73.png" alt="">
            </div>

            <div class="_ab">
              <img class="_name" src="@/assets/images/Group 140.png" alt="">
              <span></span>
              <img class="_xq" src="@/assets/images/Group 227.png" alt="">
            </div>
          </div>
        </div>

        <div class="_list1" v-if="!seen">
          <div class="_li" @click="$router.push({ path: 'visit' })">
            <div class="_img">
              <img src="../assets/images/phone/jbcl.png" alt="">
            </div>
          </div>
          <div class="_li" @click="$router.push({ path: 'visit', query: { tit: '专题展览' } })">
            <div class="_img">
              <img src="../assets/images/phone/ztzl.png" alt="">
            </div>
          </div>
          <div class="_li" @click="$router.push({ path: 'visit', query: { tit: '临时展览' } })">
            <div class="_img">
              <img src="../assets/images/phone/lszl.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 其他 -->
    <div class="_other">

      <div class="_list" v-if="seen">
        <div class="_li" @click="$router.push({ path: 'education' })">
          <div class="_liImg">
            <img src="../assets/images/Mask0 group.png" alt="">
          </div>
          <div class="_h4Img">
            <img src="../assets/images/Group 218.png" alt="">
          </div>

        </div>
        <div class="_li" @click="$router.push({ path: 'study' })">
          <div class="_liImg">
            <img src="../assets/images/Mask group.png" alt="">
          </div>
          <div class="_h4Img">
            <img src="../assets/images/Group1 218.png" alt="">
          </div>

        </div>
        <div class="_li" @click="$router.push({ path: 'boutique', query: { tit: '精品文创' } })">
          <div class="_liImg">
            <img src="../assets/images/Mask1 group.png" alt="">
          </div>
          <div class="_h4Img">
            <img src="../assets/images/Group2 218.png" alt="">
          </div>

        </div>
        <div class="_li" @click="$router.push({ path: 'volHome', query: { tit: '志愿者风采' } })">
          <div class="_liImg">
            <img src="../assets/images/Mask2 group.png" alt="">
          </div>
          <div class="_h4Img">
            <img src="../assets/images/Group3 218.png" alt="">
          </div>

        </div>
      </div>

      <div class="_list1" v-if="!seen">
        <div class="_li" @click="$router.push({ path: 'education' })">
          <div class="_img">
            <img src="../assets/images/phone/gczp.png" alt="">
          </div>
        </div>
        <div class="_li" @click="$router.push({ path: 'study' })">
          <div class="_img">
            <img src="../assets/images/phone/xsyj.png" alt="">
          </div>
        </div>
        <div class="_li" @click="$router.push({ path: 'boutique', query: { tit: '精品文创' } })">
          <div class="_img">
            <img src="../assets/images/phone/jpwc.png" alt="">
          </div>
        </div>
        <div class="_li" @click="$router.push({ path: 'volHome', query: { tit: '志愿者风采' } })">
          <div class="_img">
            <img src="../assets/images/phone/zyzfc.png" alt="">
          </div>
        </div>
      </div>


    </div>


    <fixIcon />

    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>



  </div>
</template>

<script>
import { hits } from '@/api/getData'

export default {

  data() {
    return {
      title: "",
      keywords: "",
      desc: "",
      seen: true,
    }
  },
  // 动态设置页面标题、关键字和描述等属性  
  // metaInfo() {

  //   return {
  //     title: this.title,
  //     meta: [
  //       { name: "keywords", content: this.keywords },
  //       { name: "description", content: this.desc },
  //     ]
  //   }
  // },

  created() {

  },
  mounted() {

    this.menuSeen()

    this.$scrollTop()
  },

  methods: {



    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      } 
    },
    goOther(item) {
      if (item.third_link != null && item.third_link != '') {
        const params = {
          id: item.id
        }
        hits(params)
        window.open(item.third_link)
      } else {
        this.goDetail2(item)
      }
    },
    goDetail2(item) {

      let url = location.origin
      window.open(`${url}/studyDetail?tit=动态&id=${item.id}`)


    },
    // 社教活动
    goDetail() {

      if (this.$store.state.homeData.SEA[0].third_link != null && this.$store.state.homeData.SEA[0].third_link != '') {
        const params = {
          id: this.$store.state.homeData.SEA[0].id
        }
        hits(params)
        window.open(this.$store.state.homeData.SEA[0].third_link)
      } else {
        let url = location.origin

        window.open(`${url}/studyDetail?tit=社教活动&id=${this.$store.state.homeData.SEA[0].id}`)
      }


    }

  }

};
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@mixin hidden
{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



._more
{
  color: #AD8D4F;
  display: flex;
  cursor: pointer;
  align-items: center;

  ._x
  {
    font-size: 12px;
  }

  ._gd
  {
    margin: 0 4px;
  }
}

._more
{
  @include respondTo('phone')
  {
    width: 100%;
    height: 36px;
    background: #F3F1EB;
    margin-top: 15px;
    justify-content: center;
  }
}

._wrap
{
  width: 100%;

  overflow: hidden;
}

._baseInfo
{
  width: 100%;

  background: rgba(28, 32, 39, 0.85);
  height: 210px;
  margin-top: -210px;
  overflow: hidden;
  position: relative;
  z-index: 99;

  ._auto
  {
    width: 95%;
    margin: 0 auto;
    display: flex;
    color: #E1CF9E;

    ._left
    {
      display: flex;
      margin-top: 52px;
      width: 63.5%;

      ._start
      {
        ._h4
        {
          font-size: 25px;
        }

        ._p
        {
          font-size: 60px;
          font-weight: bold;
        }

        @media (max-width:1800px)
        {
          ._p
          {
            font-size: 50px;

          }
        }
      }

      ._stop
      {
        margin-left: 66px;
        margin-right: 66px;

        p
        {
          font-size: 25px;
        }

        span
        {
          font-size: 60px;
          font-weight: bold;
        }

        @media (max-width:1800px)
        {
          span
          {
            font-size: 50px;
            font-weight: bold;
          }
        }

        ._h4
        {}
      }

      ._tel
      {
        p
        {
          font-size: 25px;
        }

        ._p
        {
          font-size: 46px;
          font-style: italic;
          letter-spacing: -3px;
        }
      }
    }

    ._right
    {
      // margin-left: 76px;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      // width: 30%;
      // flex: 1;
      width: 35.5%;
      margin-top: 15px;

      ._qrcode
      {
        width: 300px;
        height: 158px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid rgba(255, 207, 136, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 10px;

        ._code_img
        {
          width: 126px;
          height: 126px;

          img
          {
            width: 100%;
            height: 100%;
          }
        }

        ._tip
        {
          margin-left: 10px;

          p
          {
            font-size: 24px;

          }
        }
      }

      ._r_btn
      {

        display: flex;
        width: 330px;

        p
        {
          span
          {
            display: inline-block;
            width: 160px;
            height: 73px;
            line-height: 73px;
            text-align: center;
            border-radius: 4px;
            opacity: 1;
            border: 1px solid rgba(255, 207, 136, 0.4);
            margin: 10px 0;
            margin-left: 20px;
            cursor: pointer;
          }

        }
      }
    }
  }
}

._baseInfo1
{
  margin-top: -15px;
  position: relative;
  z-index: 10;
  font-size: 14px;

  ._auto{
    padding: 0 8px;
  }

  ._tt1
  {
    width: 100%;
    height: 104px;
    background-image: url("../assets/images/i1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
    padding: 30px 10px 20px 10px;

    ._text1
    {
      color: #E1CF9E;

      ._tm
      {
        color: #EED798;
        font-size: 22px;
        font-weight: bold
      }

    }

    ._text2
    {
      color: #E1CF9E;

      ._sp1
      {
        color: #EED798;
        font-size: 22px;
        font-weight: bold
      }
    }
  }

  ._tt2
  {
    width: 100%;
    height: 40px;
    background-image: url("../assets/images/i2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    color: #E1CF9E;
    display: flex;
    align-items: center;
    justify-content: center;

    ._sp1
    {
      color: #EED798;
      font-size: 20px;
      font-weight: bold;
      margin-left: 10px;

      a{
        color: #EED798;
      }
    }
  }

  ._tt3
  {
    width: 100%;
    height: 87px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    overflow: hidden;

    ._lis
    {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      text-align: center;
      color: #333;
    }

  }
}

._content
{
  width: 100%;
  margin-top: 100px;
  margin-bottom: 80px;

  ._fist
  {
    display: flex;
    width: 85%;
    margin: 0 auto;
    justify-content: space-between;

    ._f_l
    {
      width: 48%;

      ._h4
      {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 18px;

        ._bgc
        {
          display: inline-block;

          @include tit("../assets/images/h4-1.png");
          width: 299px;
          height: 52px;
        }
      }

      ._cont
      {
        ._li
        {
          display: flex;
          align-items: flex-start;
          border-bottom: 1px dashed #DCDCDC;
          padding: 19px 0 14px;
          width: 100%;
          cursor: pointer;

          ._c_l
          {
            color: #8A2415;
            margin-right: 39px;

            ._d
            {
              font-size: 32px;
            }

            span
            {
              font-size: 20px;
            }

          }

          ._c_r
          {
            width: 100%;

            ._c_h4
            {
              width: 90%;
              font-size: 22px;
              @include hidden;
            }

            p
            {

              width: 90%;
              color: #666;
              margin-top: 8px;
              @include hidden;

            }
          }
        }
      }
    }

    ._f_r
    {
      // width: 780px;

      ._h4
      {
        margin-bottom: 30px;

        ._bgc
        {
          display: inline-block;

          @include tit("../assets/images/h4-2.png");

          width: 299px;
          height: 52px;
        }

      }




      ._f_cont
      {
        width: 100%;
        position: relative;
        cursor: pointer;

        ._img
        {

          width: 100%;
          height: 478px;
          overflow: hidden;

          img
          {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all .5s;

          }

          img:hover
          {
            transform: scale(1.1);
          }
        }

        ._h5
        {
          position: absolute;
          bottom: 0;
          background-color: #1C2027;
          width: 100%;
          color: white;
          font-size: 20px;
          padding: 11px 29px;
          @include hidden()
        }
      }
    }
  }
}


._clzl
{
  width: 100%;
  height: 960px;
  background-image: url("../assets/images/Group 160.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ._au
  {
    width: 85%;
    margin: 0 auto;
    overflow: hidden;

    ._h4
    {
      @include tit("../assets/images/h4-3.png");
      margin: 0 auto;
      margin-top: 100px;
    }

    ._list
    {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      ._li
      {
        width: 460px;
        height: 650px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

      }

      ._bgImg
      {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img
        {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all .5s;

        }

        img:hover
        {
          transform: scale(1.1);
        }
      }

      ._ab
      {
        position: absolute;
        top: 90px;
        right: 40px;
        text-align: center;

        img
        {
          display: block;

        }

        ._xq
        {
          // margin-top: 100px;
          margin-left: 10px;

        }

        span
        {
          // display: inline-block;
          width: 0;
          height: 54px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          border: 1px solid;
          border-image: linear-gradient(270deg, rgba(197, 194, 190, 0.8), rgba(197, 194, 190, 0.2), rgba(197, 194, 190, 0.8), rgba(197, 194, 190, 0.8)) 1 1;
          display: block;
          margin: 54px auto;

        }
      }
    }
  }
}


._other
{
  width: 100%;
  height: 918px;
  overflow: hidden;
  background-image: url("../assets/images/bg 2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  ._list
  {
    display: flex;
    margin-top: 80px;

    ._li
    {
      width: 480px;
      height: 758px;
      position: relative;
      cursor: pointer;

      ._liImg
      {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img
        {
          width: 100%;
          height: 100%;
          transition: all .5s;
          object-fit: cover;
        }

        img:hover
        {
          transform: scale(1.1);
        }
      }

      ._h4Img
      {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -154px;
        margin-left: -50px;
      }
    }
  }
}

::v-deep .el-carousel__indicators--horizontal
{
  display: none;
}




::v-deep ._swiper
{
  .el-carousel__container
  {
    height: 955px;

  }
}

::v-deep ._swiper
{
  .el-carousel__container
  {
    @include respondTo('phone')
    {
      height: 223px;

    }

  }

  img
  {
    @include respondTo('phone')
    {
      width: 100%;
      height: 223px;
      object-fit: cover;
    }
  }


}

._content
{
  @include respondTo('phone')
  {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  ._fist
  {
    @include respondTo('phone')
    {
      width: 95%;
      display: inherit;
    }

    ._f_l
    {
      @include respondTo('phone')
      {
        width: 100%;
        display: inherit;
      }

      ._h4
      {
        @include respondTo('phone')
        {
          align-items: center;
          justify-content: center;


          ._bgc
          {
            background-image: url("../assets/images/phone/dt.png");
            width: 173px;
            height: 31px;
          }
        }
      }

      ._cont
      {

        ._li
        {}

        ._c_l
        {
          @include respondTo('phone')
          {
            margin-right: 20px !important;
          }
        }

        ._c_r
        {
          ._c_h4
          {
            @include respondTo('phone')
            {
              width: 70% !important;
            }
          }

          p
          {
            @include respondTo('phone')
            {
              width: 75% !important;
            }
          }
        }
      }
    }

    ._f_r
    {
      @include respondTo('phone')
      {
        margin-top: 30px;

        ._h4
        {

          ._bgc
          {
            background-image: url("../assets/images/phone/sjhd.png");
            width: 173px;
            height: 30px;
          }
        }
      }

      ._img
      {
        @include respondTo('phone')
        {
          height: 254px !important;
        }

      }
    }
  }
}



._clzl
{
  @include respondTo('phone')
  {
    width: 100%;
    background-image: url("../assets/images/phone/clzlbg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: auto;
    padding-bottom: 50px;
  }

  ._au
  {
    @include respondTo('phone')
    {
      width: 95%;
    }

    ._h4
    {
      @include respondTo('phone')
      {
        margin-top: 20px;
        background-image: url("../assets/images/phone/clzl.png");
        width: 173px;
        height: 30px;
      }
    }

    ._list1
    {
      @include respondTo('phone')
      {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
      }

      ._li
      {
        @include respondTo('phone')
        {
          width: 32.5%;
          height: 164px;
        }

        ._img
        {
          @include respondTo('phone')
          {
            width: 100%;
            height: 100%;
          }

          img
          {
            width: 100%;
            height: 100%;


          }
        }
      }
    }
  }
}

._other
{
  @include respondTo('phone')
  {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }

  ._list1
  {
    width: 95%;
    margin: 0 auto;

    @include respondTo('phone')
    {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    ._li
    {
      @include respondTo('phone')
      {
        width: 49%;

        ._img
        {
          width: 100%;

          img
          {
            width: 100%;
          }
        }
      }
    }
  }

}
</style>
